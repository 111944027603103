import React, { useContext } from 'react'
import './About.sass'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import bakeryImg from './1.jpg'
import Img from '../../ui/Img/Img'

function About() {
  return (
    <section id="about" className="About DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="О компании" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AboutContent />
          </div>
        </div>
      </div>
    </section>
  )
}

export const AboutContent = ({
  src = bakeryImg,
  title = 'Белая Вежа — свежайшая выпечка и хлеба высшего качества',
  description,
}) => {
  const { setShowContactUs } = useContext(AuthContext)

  const text = (
    <>
      <p>
        Пекарня Белая Вежа - это традиционные рецепты и современные технологии
        производства. Мы не используем улучшители, добавки, порошки. В составе
        нашей продукции только натуральное сырье. У нас вы можете приобрести
        горячий хлеб, пирожки, а также сладкую сдобу.
      </p>
      <p>
        Ежедневно в наш современный производственный цех поставляют свежее сырьё
        для приготовления хлеба и сдобы. Вся продукция выпекается с раннего утра
        и поступает в магазины в течение всего дня.
      </p>
      <p>
        Мы ответственно подходим к процессу приготовления хлеба и точно следуем
        рецептуре его приготовления. Производство придерживается строжайших
        стандартов качества сырья и готовой продукции.
      </p>
      <p>
        Кроме того, мы не сидим на месте и иногда совершенствуем традиционные
        рецепты, чтобы порадовать вас новинками!
      </p>
      <p style={{ fontWeight: '600', color: '#000' }}>
        Вот несколько фактов о нас:
      </p>
      <div className="Description-Facts">
        <DescriptionLine
          title="Международные стандарты"
          text="Наш технолог с международным стажем знает о идеальной рецептуре всё и даже больше.
Чиабатта, Бородинский, Латвийский, Ржаной и много-много других хлебов создано по традиционным рецептам разных стран."
        />
        <DescriptionLine
          title="Доступная цена"
          text="Достигается благодаря использованию натуральных ингредиентов. Мы используем только натуральные продукты и гордимся этим."
        />
        <DescriptionLine
          title="Натуральные составы и закваски"
          text="Мы используем только натуральные ингредиенты для приготовления собственной продукции."
        />
        <DescriptionLine
          title="Здоровый хлеб"
          text="Обратите внимание на нашу линейку здорового хлеба из гречневой, кукурузной муки, хлеб с большим содержанием семян и ржаной безглютеновый хлеб для тех, кто придерживается правильного питания!"
        />
      </div>
    </>
  )

  return (
    <div className="About-Container">
      <Img src={src} alt="" className="About-Image" />
      <div className="About-Info">
        <h5 className="Title">{title}</h5>
        <div className="Description">{description || text}</div>
        <div>
          <Button
            title="Предложить сотрудничество"
            theme="solid"
            fill="orange"
            icon="envelope"
            iconPosition="right"
            iconWeight="solid"
            size={48}
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Предложить сотрудничество',
                emailSubject: 'Предложение сотрудничества',
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

const DescriptionLine = ({ title, text }) => (
  <div>
    <p style={{ fontWeight: '600', marginBottom: '12px' }}>{title}</p>
    <p>{text}</p>
  </div>
)

export default About
