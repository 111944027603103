import React, { useContext } from 'react'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import saltImg from '../Directions/assets/salt.png'
import flourImg from './flour.png'
import './SaltCatalog.sass'

function SaltCatalog() {
  return (
    <section className="SaltCatalog DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Реализация соли" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="SaltCatalog-Container">
              <SaltTile title="Соль I помола 1 кг" src={saltImg} />
              <SaltTile title="Соль I помола 50 кг" src={saltImg} />
              <SaltTile title="Соль III помола 50 кг" src={saltImg} />
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: '64px' }}>
          <div className="col-12">
            <SectionTitle title="Реализация муки" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="SaltCatalog-Container">
              <SaltTile title="Мука в/с 50 кг" src={flourImg} />
              <SaltTile title="Мука 1с 50 кг" src={flourImg} />
              <SaltTile title="Мука ржано-пшеничная 50кг" src={flourImg} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const SaltTile = ({ title, src }) => {
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div className="SaltTile">
      <img src={src} />
      <div>
        <p className="Title">{title}</p>
        <div className="SaltTile-Button">
          <Button
            title="Запросить прайс"
            theme="solid"
            fill="orange"
            icon="envelope"
            iconPosition="right"
            iconWeight="solid"
            size={48}
            onClick={() =>
              setShowContactUs({
                show: true,
                title: 'Запросить прайс',
                emailSubject: 'Запрос прайса [Соль/Мука]',
              })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default SaltCatalog
