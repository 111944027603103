import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import ContactUs from '../../components/ContactUs/ContactUs'
import Directions from '../../components/Directions/Directions'
import About from '../../components/About/About'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Directions />
      <About />
      <ContactUs />
    </div>
  )
}

export default Home
