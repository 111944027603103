import React from 'react'
import './ContactUs.sass'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsPopUp from './ContactUsPopUp'
import { Map, Placemark, YMaps } from 'react-yandex-maps'

function ContactUs() {
  return (
    <section id="contacts" className="ContactUs DefaultSection">
      <ContactUsPopUp />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Контакты" />
            <div className="ContactUs-Container">
              <div className="ContactUs-Info">
                <div className="ContactUs-Links">
                  <ContactUsInfoTile
                    icon="mobile"
                    data={
                      <>
                        <a href="tel:*0051">*0051</a>,{' '}
                        <a href="tel:+7 (921) 277-10-90">+7 (921) 277-10-90</a>
                      </>
                    }
                    description={
                      <div className="Footer-SocialIcons">
                        <a
                          href="viber://chat?number=%2B79211530844"
                          className="Viber"
                          target="blank"
                        >
                          <Icon name="viber" weight="brand" />
                        </a>
                        <a
                          href="https://t.me/+79211530844"
                          className="Telegram"
                          target="blank"
                        >
                          <Icon name="telegram" weight="brand" />
                        </a>
                        <a
                          href="https://api.whatsapp.com/send/?phone=79211530844"
                          className="Whatsapp"
                          target="blank"
                        >
                          <Icon name="whatsapp" weight="brand" />
                        </a>
                      </div>
                    }
                  />
                  <ContactUsInfoTile
                    icon="map-marker-alt"
                    data="г. Мурманск, ул. Домостроительная, д.2, к.10"
                  />
                  <ContactUsInfoTile icon="envelope" data="info@wvezha.ru" />
                </div>
                <div className="ContactUs-Map">
                  <YMaps>
                    <Map
                      className="Map"
                      defaultState={{
                        center: [69.00152397667145, 33.11913816270443],
                        zoom: 16,
                      }}
                    >
                      <Placemark
                        geometry={[69.00152397667145, 33.11913816270443]}
                        options={{ preset: 'islands#orangeIcon' }}
                      />
                    </Map>
                  </YMaps>
                </div>
              </div>
              <ContactUsForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ContactUsInfoTile({ icon, data, description }) {
  return (
    <div className="ContactUsInfoTile">
      <div className="ContactUsInfoTile-Icon">
        <Icon name={icon} weight="solid" />
      </div>
      <div className="ContactUsInfoTile-Data">
        <p className="Data">{data}</p>
        {description && <p className="Description">{description}</p>}
      </div>
    </div>
  )
}
export default ContactUs
