import React from 'react'
import { Route } from 'react-router-dom'
import ContactUs from '../../components/ContactUs/ContactUs'
import Hero from '../../components/Hero/Hero'
import Catalog from '../../components/Catalog/Catalog'

function Bakery() {
  return (
    <div className="Bakery">
      <Hero
        title={
          <>
            Белая Вежа — пекарня
            <br />
            <span>старых традиций</span>
          </>
        }
      />
      <Route
        path={[
          '/bakery/:rootCategoryId/:subcategoryId',
          '/bakery/:rootCategoryId',
          '/bakery',
        ]}
        component={Catalog}
      />
      <ContactUs />
    </div>
  )
}

export default Bakery
