import React from 'react'
import './Warehouse.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import Img from '../../ui/Img/Img'
import img1 from './assets/1.jpg'
import img2 from './assets/2.jpg'
import img3 from './assets/3.jpg'
import img4 from './assets/4.jpg'
import img5 from './assets/5.jpg'

import icon1 from './assets/icons/icon1.png'
import icon2 from './assets/icons/icon2.png'
import icon3 from './assets/icons/icon3.png'
import icon4 from './assets/icons/icon4.png'
import icon5 from './assets/icons/icon5.png'
import icon6 from './assets/icons/icon6.png'
import icon7 from './assets/icons/icon7.png'

function Logistics() {
  return (
    <>
      <section className="Logistics DefaultSection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle title="Полный цикл" />
            </div>
          </div>
          <div className="Warehouse-Container">
            <StepContent
              position="left"
              src={img1}
              title="Прием и сортировка товара"
              description={<p>Сортируем, маркируем, паллетируем товар.</p>}
            />
            <StepContent
              position="right"
              src={img2}
              title="Хранение товара"
              description={
                <p>
                  Хранение товара на складе площадью 2500 кв.м. Обеспечиваем
                  круглосуточную охрану, видеонаблюдение и пожарную безопасность
                  вашего груза.
                </p>
              }
            />
            <StepContent
              position="left"
              src={img3}
              title="Комплектация товара"
              description={
                <p>
                  Комплектуем товары по вашему требованию: коробками, блоками,
                  паллетами. Упаковываем, маркируем, стикеруем.
                </p>
              }
            />
            <StepContent
              position="right"
              src={img4}
              title="Доставка товара"
              description={
                <p>
                  Организуем доставку собственным транспортом по Мурманску и
                  Мурманской области
                </p>
              }
            />
            <StepContent
              position="left"
              src={img5}
              title="Кросс-докинг"
              description={
                <p>
                  Кратковременное хранение груза, прямой перегруз из машины в
                  машину.
                </p>
              }
            />
          </div>
        </div>
      </section>
      <section className="Warehouse-Help DefaultSection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle title="Мы помогаем вашему бизнесу" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="Help-Container">
                <div>
                  <HelpStep
                    src={icon1}
                    title="Избежать расходов на собственные склады"
                  />
                  <HelpStep
                    src={icon2}
                    title="Снизить расходы на логистику и хранение"
                  />
                  <HelpStep
                    src={icon3}
                    title="Качественно обслуживать ваших клиентов - у нас четкая документация"
                  />
                  <HelpStep
                    src={icon4}
                    title="Сортировать, упаковывать, отгружать товар, не нарушая сроков"
                  />
                  <HelpStep
                    src={icon5}
                    title="Сократить сроки доставки груза вашим клиентам"
                  />
                  <HelpStep src={icon6} title="Снизить затраты на персонал" />
                  <HelpStep
                    src={icon7}
                    title="Привести в порядок документооборот"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function HelpStep({ src, title }) {
  return (
    <div className="Help-Step">
      <img src={src} alt={title} />
      <p className="Title">{title}</p>
    </div>
  )
}

function StepContent({ position, src, title, description, text }) {
  return (
    <div className={`Step Step_theme_${position}`}>
      <div className="Step-Container">
        <Img src={src} alt="" className="Step-Image" />
        <div className="Step-Info">
          <h5 className="Title">{title}</h5>
          <div className="Description">{description || text}</div>
        </div>
      </div>
    </div>
  )
}

export default Logistics
