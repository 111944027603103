import React from 'react'
import ContactUs from '../../components/ContactUs/ContactUs'
import Hero from '../../components/Hero/Hero'
import WarehouseInfo from '../../components/Warehouse/Warehouse'
import warehouseImg from '../../components/Warehouse/warehouse.jpg'

function Warehouse() {
  return (
    <div className="Warehouse">
      <Hero
        src={warehouseImg}
        title={
          <>
            Белая Вежа - хранение <br />
            <span>и складская обработка</span>
          </>
        }
      />
      <WarehouseInfo />
      <ContactUs />
    </div>
  )
}

export default Warehouse
