import React from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { AboutContent } from '../About/About'
import logisticsImg from './trainCargo.jpg'
import InfoLine from '../../ui/InfoLine/InfoLine'

function Logistics() {
  return (
    <section className="Logistics DefaultSection">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title="Ж/Д Логистика" />
          </div>
        </div>
        <AboutContent
          src={logisticsImg}
          title="Услуги Ж/Д логистики для доставки ваших грузов"
          description={
            <>
              <p>
                Мы оказываем полный спектр услуг от поиска вагона для вашего
                груза из любой точки России, до приемки и организации хранения в
                г. Мурманск. Мы примем и разгрузим вагон, организуем хранение на
                своем складе, доставку до вашего склада или перегруз в ваш
                транспорт. Вам больше не нужно думать о доставке грузов,
                поручите это нам.
              </p>
              <InfoLine
                icon="check"
                title="Собственная ж/д ветка до склада хранения"
              />
              <InfoLine icon="check" title="Склады хранения" />
              <InfoLine icon="check" title="Грузовой автотранспорт" />
              <InfoLine icon="check" title="Опытные грузчики" />
            </>
          }
        />
      </div>
    </section>
  )
}

export default Logistics
