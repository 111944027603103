import React, { useState } from 'react'
import './ContactUsForm.sass'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../utils/newforms/render/FormRender'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { functions } from '../../../config/firebase'

function ContactUsForm({
  subject = 'Обратная связь с сайта',
  additionalContent = null,
}) {
  const [form, setForm] = useState(
    createForm({ formPattern: new ContactForm() })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')
    const test = {
      subject,
      to: 'info@wvezha.ru',
      html: `
      Имя: ${values.name}<br/>
      E-mail: ${values.email}<br/>
      Номер телефона: ${values.phone.value}<br/>
      Комментарий: ${values.comment}<br/>
      ${additionalContent || ''}`,
    }

    func(test).then(({ data }) => {
      if (data === 'success') {
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Спасибо за обращение. Мы ответим вам в ближайшее время!',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ContactForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div className="ContactUs-Form">
      <h5>Свяжитесь с нами</h5>
      <p>Мы ответим на все ваши вопросы в течение нескольких часов</p>
      <form onSubmit={onSubmit}>
        <div className="Form-Fields">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <FormRender
            form={form}
            setForm={setForm}
            sections={[{ fields: ['name', 'email', 'phone', 'comment'] }]}
            errors={showErrors}
          />
        </div>
        <div className="ContactUsForm-Buttons">
          <Button
            type="button"
            title="Отправить"
            theme="solid"
            fill="orange"
            icon="envelope"
            iconWeight="solid"
            iconPosition="right"
            size={48}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  )
}

class ContactForm {
  constructor() {
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        label: 'Контактный телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: 'Комментарий',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default ContactUsForm
