import React from 'react'
import ContactUs from '../../components/ContactUs/ContactUs'
import Hero from '../../components/Hero/Hero'
import SaltCatalog from '../../components/SaltCatalog/SaltCatalog'
import saltAndFlourImg from '../../components/SaltCatalog/saltAndFlour.jpg'

function Salt() {
  return (
    <div className="Bakery">
      <Hero
        src={saltAndFlourImg}
        title={
          <>
            Белая Вежа — реализация соли и <br />
            <span>муки в розницу и оптом</span>
          </>
        }
      />
      <SaltCatalog />
      <ContactUs />
    </div>
  )
}

export default Salt
