import React, { useEffect, createContext, useState } from 'react'
import { Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import Auth from './pages/Auth/Auth'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import Bakery from './pages/Bakery/Bakery'
import Salt from './pages/Salt/Salt'
import Logistics from './pages/Logistics/Logistics'
import Warehouse from './pages/Warehouse/Warehouse'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState(false)

  useEffect(() => {
    const userStateListener = firebase.auth().onAuthStateChanged(setUser)
    return () => {
      userStateListener()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ user, showContactUs, setShowContactUs }}>
      {children}
    </AuthContext.Provider>
  )
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <SitePage>
        <Route path="/" exact component={Home} />
        <Route path="/bakery" component={Bakery} />
        <Route path="/salt-and-flour" exact component={Salt} />
        <Route path="/logistics-service" exact component={Logistics} />
        <Route path="/warehouse-service" exact component={Warehouse} />
        <Route path="/admin" exact component={Auth} />
        {/* <Route path="/user-agreement" exact component={UserAgreement} /> */}
      </SitePage>
    </AuthProvider>
  )
}

export default App
