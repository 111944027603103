import { db, storageRef } from '../../../config/firebase'

const deleteProduct = (productId, fileName) => {
  if (fileName) {
    return storageRef
      .child(`products/${productId}/${fileName}`)
      .delete()
      .then(() => db.collection('products').doc(productId).delete())
      .catch((e) => console.log(e))
  }
  return db.collection('products').doc(productId).delete()
}

export default deleteProduct
