import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../ui/Button/Button'
import './Directions.sass'
import pekImg from './assets/pek.jpg'
import saltImg from './assets/salt.png'
import trainImg from './assets/train.png'
import stockImg from './assets/stock.jpg'

function Directions() {
  return (
    <div className="Directions">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Directions-Container">
              <DirectionTile
                title="Пекарня"
                description="Ознакомьтесь с нашим каталогом продукции"
                src={pekImg}
                path="/bakery"
              />
              <DirectionTile
                title="Реализация соли и муки"
                description="Оптовая и розничная продажа соли и муки"
                src={saltImg}
                path="/salt-and-flour"
              />
              <DirectionTile
                title="Ж/Д Логистика"
                description="Логистические решения для доставки вашего груза"
                src={trainImg}
                path="/logistics-service"
              />
              <DirectionTile
                title="Складское хранение"
                description="Услуги хранения в подготовленных складских помещениях"
                src={stockImg}
                path="/warehouse-service"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function DirectionTile({ src, title, description, path }) {
  return (
    <Link to={path}>
      <div className="DirectionTile">
        <img src={src} alt={title} className="DirectionTile-Icon" />
        <div className="DirectionTile-Info">
          <h4 className="Info-Title">{title}</h4>
          <p className="Info-Description">{description}</p>
          <div>
            <Button
              title="Подробнее"
              theme="transparent-hug"
              color="orange"
              icon="long-arrow-right"
              iconPosition="right"
              iconWeight="regular"
              className="DirectionTile-Button"
            />
          </div>
        </div>
      </div>
    </Link>
  )
}

export default Directions
