import React from 'react'
import Icon from '../../ui/Icon/Icon'

function CallUsBar() {
  return (
    <div className="CallUsBar">
      {/* <p className="CallUsBar-Description">Позвонить нам</p> */}
      <a href="tel: +7 (921) 277-10-90" className="CallUsBar-PhoneNumber">
        <Icon name="phone-volume" weight="regular" />
        *0051, +7 (921) 277-10-90
      </a>
    </div>
  )
}

export default CallUsBar
