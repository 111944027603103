import { cloneDeep, find, omit } from 'lodash'
import React, { useState, useEffect } from 'react'
import usePrevious from '../../hooks/usePrevious'
import Button from '../../ui/Button/Button'
import PopUp from '../../ui/PopUp/PopUp'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../utils/newforms/createForm'
import FieldRender from '../../utils/newforms/render/FieldRender'
import FormRender from '../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import categoriesTree from './categoriesTree'
import './ProductManager.sass'
import fileToBase64 from '../../utils/files/fileToBase64'
import Img from '../../ui/Img/Img'
import Icon from '../../ui/Icon/Icon'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import updateDoc from '../../utils/db/updateDoc'
import addDoc from '../../utils/db/addDoc'
import getDoc from '../../utils/db/getDoc'
import uploadPhoto from './functions/uploadPhoto'

function ProductManager({ productId, rootCategoryId, subcategoryId, close }) {
  const [form, setForm] = useState(
    productId
      ? null
      : createForm({
          formPattern: new ProductForm(rootCategoryId, subcategoryId),
        })
  )

  useEffect(() => {
    if (productId) {
      getDoc({
        path: 'products',
        docId: productId,
        docIdName: 'productId',
      }).then((product) =>
        setForm(
          createForm({
            formData: product,
            formPattern: new ProductForm(
              product.rootCategory,
              product.subcategory
            ),
          })
        )
      )
    }
  }, [])

  const prevForm = usePrevious(form)

  useEffect(() => {
    if (prevForm && prevForm.rootCategory.value !== form.rootCategory.value) {
      const rootCategory = find(categoriesTree, ['id', form.rootCategory.value])
      const formClone = cloneDeep(form)
      formClone.subcategory.config.getOptions = rootCategory.subcategories.map(
        (subcategory) => ({ label: subcategory.title, value: subcategory.id })
      )
      formClone.subcategory.value = ''
      setForm(formClone)
      console.log('done')
    }
  }, [form])

  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()

    if (!isFormValid({ form })) {
      setShowErrors(true)
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните обязательные поля',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })
    const values = {
      ...data,
      photo: omit(data.photo, [
        'file',
        'fileUrl',
        'imageUrl',
        'needUpload',
        'fieldId',
        'uploadProgress',
      ]),
    }

    const opRef = productId
      ? updateDoc({ path: 'products', docId: productId, data: values })
      : addDoc({ path: 'products', data: values })

    opRef.then((docId) =>
      uploadPhoto({ form, setForm, docId })
        .then(() => {
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })

          if (!productId) close()
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ProductManager.jsx ~ line 92 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  return (
    <PopUp
      show
      close={close}
      title={`${productId ? 'Редактировать' : 'Добавить'} товар`}
      className="ProductManager"
    >
      <div className="ProductManager-Form">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="ProductManager-ProductAvatar">
          {form && (form.photo.value.publicUrl || form.photo.value.fileUrl) ? (
            <Img
              src={form.photo.value.fileUrl || form.photo.value.publicUrl}
              alt=""
              className="PhotoTile-Skeleton"
            />
          ) : (
            <div className="EmptyPhoto">
              <Icon name="images" />
            </div>
          )}
          {form && (
            <FieldRender
              key="photo"
              field={form.photo}
              form={form}
              setForm={(newForm) => onFileAdded(newForm, 'photo')}
              showErrors={showErrors}
            />
          )}
        </div>
        <form>
          <div className="ProductManager-Fields">
            <FormRender
              form={form}
              setForm={setForm}
              sections={[
                {
                  fields: [
                    'rootCategory',
                    'subcategory',
                    'article',
                    'title',
                    'weight',
                    'type',
                    'fill',
                    'expired',
                    'details',
                  ],
                },
              ]}
              sectionsSkeleton={[9]}
              errors={showErrors}
            />
          </div>
          <div className="ProductManager-Buttons">
            <Button
              type="button"
              title="Сохранить"
              theme="solid"
              fill="orange"
              size={48}
              onClick={onSubmit}
              isLoading={isLoading}
            />
            <Button
              type="button"
              title="Закрыть"
              theme="bounded"
              border="gray"
              size={48}
              onClick={close}
            />
          </div>
        </form>
      </div>
    </PopUp>
  )
}

class ProductForm {
  constructor(rootCategoryId, subcategoryId) {
    this.photo = {
      field: {
        fieldId: 'photo',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Фотография товара',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.rootCategory = {
      field: {
        fieldId: 'rootCategory',
        fieldType: 'select',
        getOptions: categoriesTree.map((c) => ({
          label: c.title,
          value: c.id,
        })),
        label: 'Категория товара',
        required: true,
        value: rootCategoryId,
      },
      render: getFieldRenderObject(),
    }
    this.subcategory = {
      field: {
        fieldId: 'subcategory',
        fieldType: 'select',
        getOptions: find(categoriesTree, [
          'id',
          rootCategoryId,
        ]).subcategories.map((sb) => ({ label: sb.title, value: sb.id })),
        label: 'Подкатегория товара',
        required: false,
        value: subcategoryId,
      },
      render: getFieldRenderObject(),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название товара',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.article = {
      field: {
        fieldId: 'article',
        fieldType: 'input',
        inputType: 'text',
        label: 'Артикул',
      },
      render: getFieldRenderObject(),
    }
    this.weight = {
      field: {
        fieldId: 'weight',
        fieldType: 'input',
        inputType: 'text',
        label: 'Масса (через запятую несколько значений)',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.type = {
      field: {
        fieldId: 'type',
        fieldType: 'input',
        inputType: 'text',
        label: 'Тип продукции (через запятую несколько значений)',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.fill = {
      field: {
        fieldId: 'fill',
        fieldType: 'input',
        inputType: 'text',
        label: 'Начинка',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.details = {
      field: {
        fieldId: 'details',
        fieldType: 'textarea-autosize',
        label: 'Состав',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.expired = {
      field: {
        fieldId: 'expired',
        fieldType: 'input',
        inputType: 'text',
        label: 'Срок годности',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default ProductManager
