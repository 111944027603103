import React, { useContext } from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'
import Button from '../../ui/Button/Button'
import Icon from '../../ui/Icon/Icon'
import { AuthContext } from '../../App'
import heroBg from './heroBg.jpg'

function Hero({ user, title, src = heroBg, ...router }) {
  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div className="Site-Hero" style={{ backgroundImage: `url(${src})` }}>
      <div className="container">
        <div className="Offer-Container">
          <div className="row">
            <div className="col-12">
              <div className="Offer">
                <h1 className="Offer-Title">
                  {title || (
                    <>
                      Белая Вежа -{' '}
                      <span>
                        хлеб и выпечка <br />
                        старых традиций
                      </span>
                    </>
                  )}
                </h1>
                <div>
                  <Button
                    title="Запросить прайс"
                    theme="solid"
                    fill="orange"
                    icon="envelope"
                    iconPosition="right"
                    iconWeight="solid"
                    size={48}
                    onClick={() =>
                      setShowContactUs({
                        show: true,
                        title: 'Запросить прайс',
                        emailSubject: 'Запрос прайса [Главная страница]',
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const mapStateToProps = (state) => ({ user: state.auth.user })

export default withRouter(Hero)
