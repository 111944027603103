import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyAIg_Hxs6buzSFsit0GZYZVCyFcQ-DbQ6A',
  authDomain: 'belayavezha.firebaseapp.com',
  databaseURL:
    'https://belayavezha-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'belayavezha',
  storageBucket: 'belayavezha.appspot.com',
  messagingSenderId: '82427235119',
  appId: '1:82427235119:web:169ede703d38840e516fda',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
