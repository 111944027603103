import React from 'react'
import './Logo.sass'
import { NavLink } from 'react-router-dom'
import logoWhite from './assets/logoWhite.svg'
import logoAccent from './assets/logoAccent.svg'

function Logo({ theme, navLink = false, path }) {
  let src
  switch (theme) {
    case 'white':
      src = logoWhite
      break
    default:
      src = logoAccent
      break
  }

  return navLink ? (
    <NavLink to={path} className="Logo">
      <img src={src} alt="Белая Вежа" />
    </NavLink>
  ) : (
    <a href={path} className="Logo">
      <img src={src} alt="Белая Вежа" />
    </a>
  )
}

export default Logo
