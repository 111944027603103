import React from 'react'
import './Footer.sass'

function Footer() {
  return (
    <footer className="Footer">
      <p>© {new Date().getFullYear()} | Белая Вежа. Все права защищены</p>
    </footer>
  )
}

export default Footer
