import React, { useContext, useEffect, useRef, useState } from 'react'
import Icon from '../../ui/Icon/Icon'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { usePopUp } from '../../hooks/usePopUp'
import './Catalog.sass'
import categoriesTree from './categoriesTree'
import { cloneDeep, find, isEmpty } from 'lodash'
import ProductManager from './ProductManager'
import Spinner from '../../ui/Spinner/Spinner'
import { db } from '../../config/firebase'
import DeletePopUp from '../../ui/PopUp/DeletePopUp/DeletePopUp'
import deleteProduct from './functions/deleteProduct'
import ProductTile from './ProductTile'
import { withRouter } from 'react-router-dom'
import { AuthContext } from '../../App'

function Catalog({ ...router }) {
  const { user } = useContext(AuthContext)
  const butRef = useRef()
  const navRef = useRef()
  const [showNav, setShowNav] = usePopUp(butRef, navRef)
  const [tree, setTree] = useState(cloneDeep(categoriesTree))
  const [showProductManager, setShowProductManager] = useState({})
  const [products, setProducts] = useState(null)
  const [showDeletePopUp, setShowDeletePopUp] = useState(false)

  const onRootCategoryClick = ({ rootCategoryId }) =>
    router.history.push(`/bakery/${rootCategoryId}`)

  const onSubcategoryClick = ({ rootCategoryId, subcategoryId }) =>
    router.history.push(`/bakery/${rootCategoryId}/${subcategoryId}`)

  useEffect(() => {
    const { rootCategoryId, subcategoryId } = router.match.params

    const treeClone = cloneDeep(tree)
    let activeCategory

    if (!rootCategoryId) activeCategory = find(treeClone, ['isActive', true])
    else activeCategory = find(treeClone, ['id', rootCategoryId])

    if (!activeCategory) return setProducts([])

    treeClone.forEach((c) => {
      c.isActive = c.id === activeCategory.id
    })

    if (!subcategoryId) {
      if (!isEmpty(activeCategory.subcategories)) {
        activeCategory.subcategories = activeCategory.subcategories.map(
          (c, i) => {
            c.isActive = i === 0
            return c
          }
        )
      }
    } else if (!isEmpty(activeCategory.subcategories)) {
      activeCategory.subcategories = activeCategory.subcategories.map((c) => {
        c.isActive = subcategoryId === c.id
        return c
      })
    }

    setTree(treeClone)

    const listener = db
      .collection('products')
      .where('rootCategory', '==', activeCategory.id)
      .where(
        'subcategory',
        '==',
        !isEmpty(activeCategory.subcategories)
          ? find(activeCategory.subcategories, ['isActive', true]).id
          : ''
      )
      .orderBy('created', 'desc')
      .onSnapshot((snap) => {
        const prds = []
        snap.forEach((p) => prds.push({ productId: p.id, ...p.data() }))
        setProducts(prds)
      })

    return () => listener()
  }, [router.location.pathname])

  return (
    <div className="Catalog DefaultSection">
      <div className="container">
        {!isEmpty(showProductManager) && user && (
          <ProductManager
            {...showProductManager}
            close={() => setShowProductManager({})}
          />
        )}
        {showDeletePopUp && user && (
          <DeletePopUp
            title="Удаление товара"
            warningMessage={`Вы уверены, что хотите удалить этот товар "${showDeletePopUp.productTitle}"?`}
            closePopUp={() => setShowDeletePopUp(false)}
            submitDelete={() =>
              deleteProduct(showDeletePopUp.productId, showDeletePopUp.fileName)
            }
          />
        )}
        <div className="row">
          <div className="col-12">
            <div
              className={[
                'Catalog-Container',
                ...(showNav ? ['Catalog-Container_showNav'] : []),
              ].join(' ')}
            >
              <div className="Catalog-LeftBlock">
                <SectionTitle title="Продукция" />
                <div ref={butRef} className="Catalog-Nav-Title">
                  Каталог продукции <Icon name="angle-down" />
                </div>
                <div ref={navRef} className="Catalog-Nav">
                  {tree.map((category) => (
                    <CatalogNavItem
                      user={user}
                      id={category.id}
                      title={category.title}
                      subcategories={category.subcategories}
                      isActive={category.isActive}
                      onRootCategoryClick={onRootCategoryClick}
                      onSubcategoryClick={onSubcategoryClick}
                      onProductAdd={setShowProductManager}
                    />
                  ))}
                </div>
              </div>
              <div className="Catalog-Content">
                {!products ? (
                  <Spinner type="module" />
                ) : isEmpty(products) ? (
                  <div className="EmptyContent">Нет товаров</div>
                ) : (
                  products.map((p) => (
                    <ProductTile
                      user={user}
                      {...p}
                      onProductEdit={() =>
                        setShowProductManager({ productId: p.productId })
                      }
                      onProductDelete={() =>
                        setShowDeletePopUp({
                          productId: p.productId,
                          fileName: p.photo.fileName
                            ? `${p.photo.fileName}.${p.photo.fileExt}`
                            : null,
                          productTitle: p.title,
                        })
                      }
                    />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CatalogNavItem = ({
  user,
  id,
  title,
  subcategories,
  isActive,
  onRootCategoryClick,
  onSubcategoryClick,
  onProductAdd,
}) => (
  <div key={id}>
    <div
      className={[
        'CatalogNavItem',
        ...(isActive ? ['CatalogNavItem_theme_active'] : []),
      ].join(' ')}
      onClick={() => onRootCategoryClick({ rootCategoryId: id })}
    >
      <Icon name="circle" weight="solid" className="CatalogNavItem-Marker" />
      {title}
      {isEmpty(subcategories) && user && (
        <Icon
          name="plus"
          className="CatalogNavItem-AddProduct"
          onClick={() =>
            onProductAdd({ rootCategoryId: id, subcategoryId: null })
          }
        />
      )}
    </div>
    {!isEmpty(subcategories) && isActive && (
      <div className="CatalogNavItem-Subcategories">
        {subcategories.map((subcategory) => (
          <p
            key={subcategory.id}
            className={[
              'CatelogNavItem-Subcategory',
              ...(subcategory.isActive
                ? ['CatelogNavItem-Subcategory_theme_active']
                : []),
            ].join(' ')}
            onClick={() =>
              onSubcategoryClick({
                rootCategoryId: id,
                subcategoryId: subcategory.id,
              })
            }
          >
            <span>{subcategory.title}</span>
            {user && (
              <Icon
                name="plus"
                onClick={() =>
                  onProductAdd({
                    rootCategoryId: id,
                    subcategoryId: subcategory.id,
                  })
                }
              />
            )}
          </p>
        ))}
      </div>
    )}
  </div>
)

export default withRouter(Catalog)
