import React from 'react'
import ContactUs from '../../components/ContactUs/ContactUs'
import Hero from '../../components/Hero/Hero'
import LogisticsInfo from '../../components/Logistics/Logistics'
import logisticsImg from '../../components/Logistics/train.jpg'

function Logistics() {
  return (
    <div className="Logistics">
      <Hero
        src={logisticsImg}
        title={
          <>
            Белая Вежа - <br />
            <span>услуги Ж/Д логистики</span>
          </>
        }
      />
      <LogisticsInfo />
      <ContactUs />
    </div>
  )
}

export default Logistics
