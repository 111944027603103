import React from 'react'
import ReactTooltip from 'react-tooltip'
import Button from '../../ui/Button/Button'
import Icon from '../../ui/Icon/Icon'
import Img from '../../ui/Img/Img'

const ProductTile = ({
  user,
  productId,
  photo,
  article,
  title,
  weight,
  type,
  fill,
  expired,
  details,
  onProductEdit,
  onProductDelete,
}) => (
  <div key={productId} className="ProductTile">
    <ReactTooltip className="ProductDetails-Tooltip" />
    <div className="Product-Info">
      <div className="Product-Img">
        {photo.publicUrl ? (
          <Img className="Product-Img" src={photo.publicUrl} />
        ) : (
          <div className="NoProductPhoto">
            <Icon name="bread-loaf" />
          </div>
        )}
      </div>
      <div className="Product-Info-Container">
        <h5 className="Title">{title}</h5>
        {user && (
          <div className="ManageProductButtons">
            <Button
              type="button"
              title="Редакт."
              icon="pencil-alt"
              theme="solid"
              fill="orange"
              size={28}
              onClick={onProductEdit}
            />
            <Button
              type="button"
              title="Удалить"
              theme="bounded"
              border="gray"
              size={28}
              icon="trash-alt"
              onClick={onProductDelete}
            />
          </div>
        )}
        <p className="DescriptionLine">
          Артикул: <span>{article}</span>
        </p>
        {fill && (
          <p className="DescriptionLine">
            Начинка: <span>{fill}</span>
          </p>
        )}
        <p className="DescriptionLine DescriptionLine-Accent">
          Срок годности: <span>{expired}</span>
        </p>
        <p data-tip={details} className="DescriptionLine ProductDetailsText">
          <Icon name="info-circle" /> Подробнее о составе
        </p>
      </div>
    </div>
    <div className="Product-Details">
      <div className="Detail">
        <p className="Title">Масса</p>
        <div className="Detail-Types">
          {weight.split(',').map((w) => (
            <span>{w}</span>
          ))}
        </div>
      </div>
      <div className="Detail">
        <p className="Title">Тип</p>
        <div className="Detail-Types">
          {type.split(',').map((c) => (
            <span>{c}</span>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default ProductTile
