import { storageRef } from '../../config/firebase'
const uploadFiles = ({ files, storagePath }) =>
  Promise.all(files.map((f) => uploadFile({ fileObject: f, storagePath })))

export const uploadFile = ({ fileObject, storagePath }) => {
  const { fileName, fileExt, file } = fileObject

  const uploadTask = storageRef.child(`${storagePath}/${fileName}.${fileExt}`).put(file)

  uploadTask.on(
    'state_changed',
    (snap) => {
      let progress = (snap.bytesTransferred / snap.totalBytes) * 100
      fileObject.uploadProgress = progress
    },
    (error) => {
      console.log('File upload error ->', error)
    },
    () => {
      // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => (publicUrl = downloadURL))
    },
  )

  return uploadTask.then(() =>
    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => ({ fileName, publicUrl: downloadURL })),
  )
}

export default uploadFiles
