import React from 'react'
import { NavLink } from 'react-router-dom'
import './Button.sass'
import SpinnerWhite from './assets/spinner-white.svg'
import SpinnerGray from './assets/spinner-gray.svg'
import Icon from '../Icon/Icon'

const Button = React.forwardRef(
  (
    {
      id,
      type = 'button',
      path,
      targetBlank = false,
      title,
      children,
      className,
      theme = 'transparent',
      fill = '',
      color = 'accent',
      border = '',
      onClick,
      isLoading,
      icon,
      iconWeight,
      iconPosition = 'left',
      style,
      size,
      tooltip,
    },
    ref
  ) => {
    let buttonTheme = ''

    switch (theme.toLowerCase()) {
      case 'circle':
        buttonTheme = 'Button_theme_circle'
        break
      case 'solid':
        buttonTheme = 'Button_theme_solid'
        break
      case 'bounded':
        buttonTheme = 'Button_theme_bounded'
        break
      case 'transparent':
        buttonTheme = 'Button_theme_transparent'
        break
      case 'transparent-hug':
        buttonTheme = 'Button_theme_transparent-hug'
        break
      default:
        buttonTheme = 'Button_theme_solid'
        break
    }

    const buttonColor = `Button_color_${color}`
    const buttonFill = fill ? `Button_fill_${fill}` : ''
    const buttonBorder = border ? `Button_border_${border}` : ''
    const spinner = color === 'white' ? SpinnerGray : SpinnerWhite

    switch (type.toLowerCase()) {
      case 'link':
        return (
          <a
            ref={ref}
            id={id}
            href={path}
            target={targetBlank ? 'blank' : ''}
            data-tip={tooltip}
            className={[
              'Button',
              ...(buttonTheme ? [buttonTheme] : []),
              ...(buttonColor ? [buttonColor] : []),
              ...(buttonFill ? [buttonFill] : []),
              ...(buttonBorder ? [buttonBorder] : []),
              ...(className ? [buttonTheme, className] : []),
              ...(icon || isLoading
                ? [`Button_icon_position_${iconPosition}`]
                : []),
              ...(size ? [`Button_size_${size}`] : []),
            ].join(' ')}
          >
            <div className="Button-Content">{children || title}</div>
          </a>
        )
      case 'navlink':
        return (
          <NavLink
            ref={ref}
            to={path}
            className={[
              'Button',
              ...(buttonTheme ? [buttonTheme] : []),
              ...(buttonColor ? [buttonColor] : []),
              ...(buttonFill ? [buttonFill] : []),
              ...(buttonBorder ? [buttonBorder] : []),
              ...(className ? [buttonTheme, className] : []),
              ...(icon || isLoading
                ? [`Button_icon_position_${iconPosition}`]
                : []),
              ...(size ? [`Button_size_${size}`] : []),
            ].join(' ')}
          >
            <div className="Button-Content">
              {(isLoading || icon) && (
                <span className="Button-IconContainer">
                  {isLoading ? (
                    <img src={spinner} alt="Loading" />
                  ) : (
                    <Icon name={icon} weight={iconWeight || 'regular'} />
                  )}
                </span>
              )}
              <span data-tip={tooltip}>{children || title}</span>
            </div>
          </NavLink>
        )
      default:
        return (
          <button
            ref={ref}
            id={id}
            type={type}
            data-tip={tooltip}
            data-effect="solid"
            className={[
              'Button',
              ...(buttonTheme ? [buttonTheme] : []),
              ...(buttonColor ? [buttonColor] : []),
              ...(buttonFill ? [buttonFill] : []),
              ...(buttonBorder ? [buttonBorder] : []),
              ...(className ? [buttonTheme, className] : []),
              ...(icon || isLoading
                ? [`Button_icon_position_${iconPosition}`]
                : []),
              ...(size ? [`Button_size_${size}`] : []),
            ].join(' ')}
            onClick={onClick}
            style={style}
          >
            <div className="Button-Content">
              {(isLoading || icon) && (
                <span className="Button-IconContainer">
                  {isLoading ? (
                    <img src={spinner} alt="Loading" />
                  ) : (
                    <Icon name={icon} weight={iconWeight || 'regular'} />
                  )}
                </span>
              )}
              <span className="Button-Content">{children || title}</span>
            </div>
          </button>
        )
    }
  }
)

export default Button
