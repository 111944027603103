const categoriesTree = [
  {
    id: 'sdoba',
    title: 'Сдоба',
    subcategories: [
      { id: 'bulochka', title: 'Булочка', isActive: true },
      { id: 'vatrushka', title: 'Ватрушка' },
      { id: 'sochnik', title: 'Сочник' },
      { id: 'pirog', title: 'Пирог' },
      { id: 'keks', title: 'Кекс' },
      { id: 'maffin', title: 'Маффин' },
      { id: 'sdoba-other', title: 'Сдоба' },
      { id: 'lepeshka', title: 'Лепешка' },
      { id: 'sosiska-v-teste', title: 'Сосиска в тесте' },
      { id: 'korzhik', title: 'Коржик' },
      { id: 'rozhki', title: 'Рожки' },
      { id: 'pirozhok', title: 'Пирожок' },
    ],
    isActive: true,
  },
  {
    id: 'pizza',
    title: 'Пицца',
    subcategories: [],
  },
  {
    id: 'cookies',
    title: 'Печенье',
    subcategories: [],
  },
  {
    id: 'bread-and-loaf',
    title: 'Хлеба и батоны',
    subcategories: [
      { id: 'bread', title: 'Хлеб' },
      { id: 'loaf', title: 'Батон' },
      { id: 'bulka', title: 'Булка' },
      { id: 'baguette', title: 'Багет' },
      { id: 'pita', title: 'Лаваш' },
      { id: 'sennit', title: 'Плетенка' },
      { id: 'matnakash', title: 'Матнакаш' },
      { id: 'karavay', title: 'Каравай' },
    ],
  },
]

export default categoriesTree
